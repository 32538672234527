import React from 'react';

// Components
import {
  Layout,
  Container,
  ContentBlock,
} from '../components';

// Data
import data from '../data/pages/privacy-policy.json';

const PrivacyPolicy = () => {
  const content = data.content;

  return (
    <Layout pageContext={data}>
      <Container addSpacers>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
